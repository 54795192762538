body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: black;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1)); */
  color: white;
  font-family: "Outfit";
}

.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */

  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), black), url("../public/hero.jpg");

  display: grid;
  grid-template-rows: 20% 20% 20% 40%;
}

.hero-title {
  grid-row: 4 / 5;
  font-size: 13vmin;
}

.catchp {
  font-family: "Junge";
  font-size: 4vmin;
}

.industries {
  font-size: 15vmin;
  top: -10vmin;
}

.pageImg {
  object-fit: cover;
}

.info {
  text-align: justify;
  font-size: 4vmin;
}

.multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sectionHead {
  font-size: 10vmin;
}

.secLine {
  height: 13vmin;
  width: 3vmin;
  min-width: 15px;
}

.w-15 {
  max-width: 15%;
}

.w-85 {
  max-width: 85%;
}

.minw-50 {
  min-width: 50%;
}